



































































































































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      loading: true,
      paginate: {
        keywords: '',
        page: 1,
        size: 10,
        total: 0,
        orderBy: {},
      },
      entities: [],
      selection: [],
      showDialog: false,
      form: {
        wallets: '',
      },
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      const res = await this.$http.request({
        url: 'wallets',
        method: 'get',
        params: this.paginate,
      })
      this.entities = res.data.data
      this.loading = false
      this.paginate.total = res.data.meta.total
    },
    resetSearch() {
      this.paginate.keywords = ''
      this.paginate.page = 1
      this.fetch()
    },
    handleSizeChange(size: number) {
      this.paginate.size = size
      this.fetch()
    },
    handleCurrentChange(page: number) {
      this.paginate.page = page
      this.fetch()
    },
    handleSortChange({ prop, order }: { prop: any; order: any }) {
      if (order) {
        this.paginate.orderBy = {
          prop,
          order: order.replace('ending', ''),
        }
      } else {
        this.paginate.orderBy = {}
      }
      this.fetch()
    },
    handleSelectionChange(val: any) {
      this.selection = val.map((i: any) => i.id)
    },
    handleRemove(row: any) {
      this.$confirm(`此操作将永久删除"${row.id}", 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$http.delete(`wallets/${row.id}`)
        this.$message.success('删除成功')
        this.fetch()
      })
    },
    // 导入
    handleExport() {
      this.showDialog = true
    },
    async handleDownloadTemplate() {
      const response = await this.$http.post(
        'wallets/template',
        {},
        {
          responseType: 'arraybuffer',
        },
      )
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      })
      let downloadElement = document.createElement('a')
      let href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `table.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    },
    async handleWalletImportSubmit() {
      await this.$http.post('wallets/import/save', {
        wallets: this.form.wallets,
      })
      this.$message.success('导入成功')
      this.form.wallets = ''
      this.showDialog = false
      this.fetch()
    },
    handleUploadError(error: any) {
      this.$message.error(JSON.parse(error.message).error)
    },
    afterImport(data: any[]) {
      if (!data?.length) return
      this.form.wallets = data
        .map((i: Array<any>) => i.join(','))
        .join('\n')
        .trim()
    },
    async handleSelectDelete() {
      this.$confirm('确认要删除吗?', {
        type: 'warning',
      }).then(async () => {
        await this.$http.delete('wallets/selection/delete', {
          params: {
            selection: this.selection,
          },
        })
        this.$message.success('删除成功')
        this.fetch()
      })
    },
    async handleUpdateBalance() {
      this.$confirm('更新余额将会更新未启用和无余额的钱包，是否继续?', {
        type: 'warning',
      }).then(async () => {
        await this.$http.post('wallets/update/balance')
        this.$message.success('删除成功')
        this.fetch()
      })
    },
    async handleWalletStatusChange(wallet: any) {
      this.loading = true
      try {
        await this.$http.put(`wallets/${wallet.id}?update=0`, wallet)
        this.$message.success('更新成功')
      } finally {
        this.loading = false
      }
    },
  },
})
